<template>
  <v-card width="90%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Простые карты режимов</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <CardsTable :perPage="perPage" :key="reloadCardsTable">
      <template v-slot:select="{ card }">
        <v-checkbox
          :value="card.id"
          v-model="selectedCards"
          v-if="Number(card.status) !== 2"
        />
      </template>
      <template v-slot:action="{ card }">
        <div style="display: flex;">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="'/simple-card/card/update?id=' + card.id"
                :disabled="Number(card.status) !== 1"
                >
                <v-icon color="primary" v-on="on">mdi-file-edit</v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="'/simple-card/card/copy?id=' + card.id">
                <v-icon color="primary" v-on="on">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Копировать</span>
          </v-tooltip>
        </div>
      </template>
    </CardsTable>
    <v-card-actions>
      <v-btn
        color="default"
        :disabled="selectedCards.length === 0"
        @click="archiveCards"
        >
        В архив
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" to="/simple-card/card/create">Создать</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from './components/CardsTable.vue'

export default {
  components: {
    CardsTable,
  },
  data: () => ({
    selectedCards: [],
    reloadCardsTable: 0,
    perPage: 5
  }),
  computed: {
    ...mapState('organization', {
      orgId: state => state.profile.id
    })
  },
  methods: {
    archiveCards(item) {
      let url = '/simple-card/card/archive'
      this.$axios.post(url, { ids: this.selectedCards }).then(r => {
        this.reloadCardsTable = Date.now()
      }).catch(() => {})
    },
  },
}
</script>
